import React, { } from "react"
import { useQuery } from "@apollo/react-hooks"


import './style.scss'
import { PhoneAgentConfigForm } from "components/front/PHONE/Agent/Config/Form/PhoneAgentConfigForm"
import configListGql from "graphql/APP/User/Config/query/userConfigList.gql"
import { Corp, Hotel, NotificationTypeEnum, UserConfig, UserInput, UserRoleEnum } from "model"
import { IconTrash, SelectInput } from "@zipou/front_tools"
import corpListGql from "graphql/APP/Corp/query/corpList.gql"
import hotelListByCorpId from "graphql/PMS/Hotel/query/hotelListByCorpId"
import { PhoneSipConfigForm } from "components/front/PHONE/Sip/Config/Form/PhoneSipConfigForm"

type UserFormProps = {
  user: UserInput,
  onChange: (user: UserInput) => void,
  errors: any,
}

export const UserForm = ({ user, onChange, errors }: UserFormProps) => {

  const configListResponse = useQuery<{ list: UserConfig[] }>(configListGql)
  const hotelListResponse = useQuery<{ list: Hotel[] }>(hotelListByCorpId, {
    variables: {
      corpId: user?.corpId,
    },
    skip: !user?.corpId
  })
  const configList: UserConfig[] = configListResponse?.data?.list
  const hotelList = hotelListResponse?.data?.list

  const { data: { list: corpList } = {} } = useQuery<{ list: Corp[] }>(corpListGql)



  return <div className="user-details-container">
    <div className="input-group">
      <span className="input-group-text">Nom</span>
      <input className="form-control" type="text" value={user?.name || ""} placeholder="Nom" onChange={(e) => {
        onChange({
          ...user,
          name: e.target.value,
        })
      }} />
    </div>

    <SelectInput defaultLabel="Aucun" isDefaultActive label="Corp" id={"corpId"} value={user?.corpId} choiceList={corpList?.map((corp) => ({ id: corp?.id, label: corp?.name }))} errors={errors} onChange={(corpId) => {
      onChange({
        ...user,
        corpId,
      })
    }} />

    <div className="input-group">
      <span className="input-group-text">Email</span>
      <input className="form-control" type="text" value={user?.email || ""} placeholder="Email" onChange={(e) => {
        onChange({
          ...user,
          email: e.target.value,
        })
      }} />
    </div>
    <div className="input-group">
      <span className="input-group-text">Role</span>
      <div className="form-control" style={{ height: "100%" }}>
        {Object.values(UserRoleEnum).map((role) => {
          const key = `role_${role}`
          return <span key={`${key}`} className="ml-2 mr-2" style={{ whiteSpace: "nowrap" }}>
            <input type="checkbox" checked={user?.roles?.includes(role)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                roles: checked ? [...(user?.roles ? user?.roles : []), role] : user?.roles?.filter((el: string) => el !== role)
              })
            }} />
            <label className="" htmlFor={key}>{role}</label>
          </span>
        })}
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">Hotels</span>
      <div className="form-control" style={{ height: "100%" }}>
        {!(hotelList?.length > 0) && <div className="alert alert-warning">
          Aucun hotel, avez vous selectionné un group corp ?
        </div>}
        {hotelList?.map((hotel) => {
          const { id, name } = hotel;
          const key = `role_${id}`
          return <span className="ml-2 mr-2" key={`${key}`} style={{ whiteSpace: "nowrap" }}>
            <input type="checkbox" checked={user?.hotels?.includes(id)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                hotels: checked ? [...(user?.hotels ? user?.hotels : []), id] : user?.hotels?.filter((el: string) => el !== id)
              })
            }} />
            <label className="" htmlFor={key}>{name}</label>
          </span>
        })}
      </div>
    </div>
    <div className="input-group">
      <span className="input-group-text">Config</span>
      <select className="form-control" value={user?.configId || ""} onChange={(e: any) => {
        onChange({
          ...user,
          configId: e.target.value,
        })
      }}>
        <option value=""></option>
        {configList?.map(config => <option key={`${config?.id}`} value={config?.id}>{config?.name}</option>)}
      </select>
    </div>
    <div className="input-group">
      <span className="input-group-text">PhoneAgentConfig</span>
      <div className="form-control" style={{ height: "100%" }}>
        <PhoneAgentConfigForm phoneAgentConfig={user?.PhoneAgentConfig} onChange={(v: any) => {
          onChange({
            ...user,
            PhoneAgentConfig: v
          })
        }} />
      </div>
      <span className="input-group-text" onClick={() => {
        onChange({
          ...user,
          PhoneAgentConfig: null
        })
      }}>
        <IconTrash />
      </span>
    </div>
    <div className="input-group">
      <span className="input-group-text">PhoneSipConfig</span>
      <div className="form-control" style={{ height: "100%" }}>
        <PhoneSipConfigForm phoneSipConfig={user?.phoneSipConfig} errors={errors?.PhoneSipConfig} onChange={(v) => {
          onChange({
            ...user,
            phoneSipConfig: v
          })
        }} />
      </div>
      <span className="input-group-text" onClick={() => {
        onChange({
          ...user,
          phoneSipConfig: null
        })
      }}>
        <IconTrash />
      </span>
    </div>
    <div className="input-group">
      <span className="input-group-text">Notification via email</span>
      <div className="form-control" style={{ height: "100%" }}>
        {Object.keys(NotificationTypeEnum).map(key => {
          //@ts-ignore
          const type = NotificationTypeEnum[key] as NotificationTypeEnum
          return <span key={`${key}`} className="ml-2 mr-2" style={{ whiteSpace: "nowrap" }}>
            <input type="checkbox" checked={user?.notificationListToMail?.includes(type)} id={key} onChange={(e: any) => {
              const checked = e.target.checked
              onChange({
                ...user,
                notificationListToMail: checked ? [...(user?.notificationListToMail ? user?.notificationListToMail : []), type] : user.notificationListToMail?.filter((el: string) => el !== type)
              })
            }} />
            <label className="" htmlFor={key}>{type}</label>
          </span>
        })}
      </div>
    </div>
  </div>
}